import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { WpImageType } from "../types/WpImage";

type Props = {
  image?: WpImageType;
  className?: string;
};

/**
 * 画像を返します。
 * 画像が読み込まれなかった場合、デフォルトのダミー画像を返します。
 * 親にtext-centerで中央揃え
 */
export const WpImage: React.VFC<Props> = ({ image, className }) => {
  const data = image?.localFile?.childImageSharp?.gatsbyImageData;
  const alt = image?.altText || "";
  if (!image || !data) {
    return (
      <StaticImage
        className={className}
        src="../images/default.png"
        alt="defaltimage"
      />
    );
  }
  return <GatsbyImage image={data} alt={alt} className={className} />;
};
