import { graphql, PageProps } from "gatsby";
import React, { useState } from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import { Card02 } from "../components/Card02";
import { Heading01 } from "../components/Heading01";
import { Layout } from "../components/Layout/index";
import { Tab01 } from "../components/Tab01";
import bgimgPc from "../images/h1/h1case.png";
import bgimgSp from "../images/h1/h1case_sp.png";

const CaseIndexPage: React.VFC<PageProps<GatsbyTypes.CaseIndexQuery>> = ({
  data,
}) => {
  const [selectedTab, setSelectedTab] = useState(1);

  // // 一覧から選ぶを押したときの動作
  const TabClicked01 = () => {
    if (selectedTab !== 1) {
      setSelectedTab(1);
    }
  };

  const TabClicked02 = () => {
    if (selectedTab !== 2) {
      setSelectedTab(2);
    }
  };

  const TabClicked03 = () => {
    if (selectedTab !== 3) {
      setSelectedTab(3);
    }
  };

  return (
    <Layout title="施工事例" description="ミスギの施工事例一覧ページです。">
      <Heading01
        label="CASE"
        smallLabel="施工事例"
        bgimg={[bgimgPc, bgimgSp]}
      />
      <Breadcrumb labels={[{ url: "", text: "施工事例" }]} />
      <div className="l-container-sm pt-8 pb-14 pc:py-[120px]">
        <div>
          {/* -----------------タブ------------- */}
          <div className="mb-12 flex w-full flex-col tb:flex-row pc:mb-14">
            <Tab01
              label="スロープ・コーナー"
              isSelected={selectedTab === 1}
              onClick={TabClicked01}
            />
            <Tab01
              label="カーストッパー"
              isSelected={selectedTab === 2}
              onClick={TabClicked02}
            />
            <Tab01
              label="その他"
              isSelected={selectedTab === 3}
              onClick={TabClicked03}
            />
          </div>
        </div>
        <div className="mt-16">
          <div className="mt-12">
            <ul className="grid grid-cols-1 gap-x-0 gap-y-8 tb:grid-cols-2 tb:gap-x-[20px] tb:gap-y-12 pc:grid-cols-3">
              {selectedTab === 1 &&
                data.slopecorner.edges.map(
                  (nodes) =>
                    nodes.node.title && (
                      <Card02
                        key={nodes.node.title}
                        heading={nodes.node.title}
                        link={`/case/${nodes.node.slug || ``}`}
                        description={nodes.node.caseAcf?.catch}
                        img={nodes.node.featuredImage?.node}
                      />
                    )
                )}
              {selectedTab === 2 &&
                data.carstopper.edges.map(
                  (nodes) =>
                    nodes.node.title && (
                      <Card02
                        key={nodes.node.title}
                        heading={nodes.node.title}
                        link={`/case/${nodes.node.slug || ``}`}
                        description={nodes.node.caseAcf?.catch}
                        img={nodes.node.featuredImage?.node}
                      />
                    )
                )}
              {selectedTab === 3 &&
                data.others.edges.map(
                  (nodes) =>
                    nodes.node.title && (
                      <Card02
                        key={nodes.node.title}
                        heading={nodes.node.title}
                        link={`/case/${nodes.node.slug || ``}`}
                        description={nodes.node.caseAcf?.catch}
                        img={nodes.node.featuredImage?.node}
                      />
                    )
                )}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query CaseIndex {
    slopecorner: allWpCase(
      sort: { fields: date, order: ASC }
      filter: {
        terms: { nodes: { elemMatch: { slug: { eq: "slopecorner" } } } }
      }
    ) {
      edges {
        node {
          title
          slug
          caseAcf {
            catch
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    carstopper: allWpCase(
      sort: { fields: date, order: ASC }
      filter: {
        terms: { nodes: { elemMatch: { slug: { eq: "carstopper" } } } }
      }
    ) {
      edges {
        node {
          title
          slug
          caseAcf {
            catch
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    others: allWpCase(
      sort: { fields: date, order: ASC }
      filter: { terms: { nodes: { elemMatch: { slug: { eq: "others" } } } } }
    ) {
      edges {
        node {
          title
          slug
          caseAcf {
            catch
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CaseIndexPage;
