import React from "react";

import { Props } from "../../types/Tab01";

/**
 * タブ
 */
export const Presenter: React.VFC<Props> = ({ label, isSelected, onClick }) => {
  return (
    <li
      onClick={onClick}
      className={`relative w-full cursor-pointer list-none p-4 text-center text-[16px] font-medium
       ${
         isSelected
           ? `bg-primary text-white before:absolute before:bottom-4 before:left-1/2 before:-z-10 before:h-4 before:w-8
         before:translate-y-full before:-translate-x-1/2 before:border-[24px] before:border-solid before:border-transparent
        before:bg-white tb:before:border-t-primary tb:before:content-['']`
           : `border-y border-solid border-[#E0E0E0] bg-white text-primary`
       }`}
    >
      {label}
    </li>
  );
};
